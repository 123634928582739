(function ($) {
  $(function () {


    function setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toGMTString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    function checkCookie() {
      let user = getCookie("cookieBox");
      if (user != "" && user != null) {
        jQuery("#et-cookie").css("display", "none");
      } else {
        jQuery("#et-cookie").css("display", "flex");
      }
    }

    checkCookie();

    jQuery("#btn-cookie-confirm").click(function () {
      setCookie("cookieBox", "true", 30);
      jQuery("#et-cookie").css("display", "none");
    });

    jQuery("#btn-cookie-close").click(function () {
      jQuery("#et-cookie").css("display", "none");
    });




    // init Isotope
    var $grid = $('.filter-grid-content').isotope({
      itemSelector: '.element-item',
      layoutMode: 'fitRows'
    });
    // filter functions
    var filterFns = {
      // show if number is greater than 50
      numberGreaterThan50: function () {
        var number = $(this).find('.number').text();
        return parseInt(number, 10) > 50;
      },
      // show if name ends with -ium
      ium: function () {
        var name = $(this).find('.name').text();
        return name.match(/ium$/);
      }
    };
    // bind filter button click
    $('.filters-button-group').on('click', 'button', function () {
      var filterValue = $(this).attr('data-filter');
      // use filterFn if matches value
      filterValue = filterFns[filterValue] || filterValue;
      $grid.isotope({
        filter: filterValue
      });
    });
    // change is-checked class on buttons
    $('.button-group').each(function (i, buttonGroup) {
      var $buttonGroup = $(buttonGroup);
      $buttonGroup.on('click', 'button', function () {
        $buttonGroup.find('.is-checked').removeClass('is-checked');
        $(this).addClass('is-checked');
      });
    });

    /*
     * Navbar fixed
     */
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 1) {
        $('.navbar').addClass('navbar-fixed-top');
        $('#header-top').addClass('active');
      } else {
        $('.navbar').removeClass('navbar-fixed-top');
        $('#header-top').removeClass('active');

      }
    });

    /*
     * Toggle burger menu on mobile
     */
    $('.menu-btn-burger').on('click', function () {
      $('#nav-icon').toggleClass('open-custom-menu');
      $('body').toggleClass('fixedPosition');
      $(".mobile-menu").toggleClass('sliding-open');
    });

    /*
     * Owl carousel
     */
    $('.owl-home').owlCarousel({
      margin: 20,
      items: 3,
      loop: true,
      autoplay: true,
      autoplayTimeout: 4500,
      nav: false,
      dots: false,
      animateOut: 'fadeOut',
      // navText: ["<i class='dr-arrow-left'></i>", "<i class='dr-arrow-right'></i>"],
      // navContainer: '#customNav',
      responsive: {
        0: {
          items: 1
        },
        700: {
          items: 2,
          margin: 0,
        },
        1200: {
          items: 3,
        }
      }
    });

    $('.owl-slider').owlCarousel({
      margin: 20,
      items: 1,
      loop: true,
      autoplay: true,
      autoplayTimeout: 4500,
      nav: false,
      dots: false,
      animateOut: 'fadeOut',
      // navText: ["<i class='dr-arrow-left'></i>", "<i class='dr-arrow-right'></i>"],
      // navContainer: '#customNav',
    });

    /*
     * Video modal 
     */
    $("body").on("click", "#play-video-modal", function (e) {
      e.preventDefault();
      $("#video-modal").modal("show");
      $("#video-modal iframe")[0].src += "?autoplay=1";
    });

    $("#video-modal").on('hidden.bs.modal', function (e) {
      var videoUrl = $("#video-modal iframe")[0].src;
      videoUrl = videoUrl.replace("?autoplay=1", "");
      $("#video-modal iframe")[0].src = videoUrl;
    });

    /*
     * Open modal
     */

    $('#open-modal').on('click', function (e) {
      e.preventDefault();
      $('#modal').modal('show');
    });

    /*
     * Youtube lazy loading
     */
    $('#play-video-btn').on('click', function (e) {
      e.preventDefault();
      $('#youtubeModal').modal('show');
    });

    $("#youtubeModal").on('hidden.bs.modal', function (e) {
      $("#youtubeModal iframe").attr("src", $("#youtubeModal iframe").attr("src"));
    });



    $('#play-video-btn').on('click', function () {
      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);
    })

    window.onYouTubeIframeAPIReady = function () {
      var videoModules = document.querySelectorAll('.video');
      videoModules = Array.prototype.slice.call(videoModules);
      videoModules.forEach(initializeVideoModule);
    }

    function initializeVideoModule(videoModule) {
      var player = new YT.Player(videoModule.querySelector('.video-placeholder'), {
        videoId: videoModule.dataset.videoId,
        events: {
          onStateChange: function (event) {
            var isEnded = event.data === YT.PlayerState.ENDED;
            videoModule.classList[isEnded ? 'remove' : 'add']('playing');
            if (isEnded) {
              player.destroy();
              videoModule.querySelector('.video-layer').innerHTML = (
                '<div class="video-placeholder"></div>'
              );
              initializeVideoModule(videoModule);
            }
          }
        }
      });
    }

    /*
     * Scroll up button
     */
    $(window).scroll(function () {
      if ($(this).scrollTop() > 150) {
        $('.scroll-to-top').addClass('show');
      } else {
        $('.scroll-to-top').removeClass('show');
        $('.scroll-to-top').addClass('animated bounceOutDown');
        $('.scroll-to-top').removeClass('animated bounceOutUp');
        $('.scroll-to-top i').css('transform', 'rotateY(0) scale(1)');
      }
    });
    $('.scroll-to-top').on("click", function () {
      $('.scroll-to-top').addClass('animated bounceOutUp');
      $('.scroll-to-top i').css('transform', 'rotateY(180deg) scale(1.2)');
      $("html, body").animate({
        scrollTop: 0
      }, 1000);
      return false;
    });

    /*
     * Lightbox gallery
     */
    $('.gallery-images').lightGallery({
      selector: '.gallery-item',
      share: true,
      download: true,
      rotate: false,
      showThumbByDefault: true,
      loop: false,
      slideEndAnimatoin: false,
      thumbnail: true,
      allowMediaOverlap: true,
      toggleThumb: true,
      youtubePlayerParams: {
        loadYoutubeThumbnail: true,
        youtubeThumbSize: 'default',
        modestbranding: 1,
        showinfo: 0,
        rel: 0
      }
    });


    $('.post-gallery-images').lightGallery({
      selector: '.post-gallery-item',
      share: true,
      download: true,
      rotate: false,
      showThumbByDefault: true,
      loop: false,
      slideEndAnimatoin: false,
      thumbnail: true,
      allowMediaOverlap: true,
      toggleThumb: true,
      youtubePlayerParams: {
        loadYoutubeThumbnail: true,
        youtubeThumbSize: 'default',
        modestbranding: 1,
        showinfo: 0,
        rel: 0
      }
    });


    /*
     * Mmenu light 
     */
    var menu = new MmenuLight(
      document.querySelector('#my-menu'),
      'all'
    );
    if (menu) {
      var navigator = menu.navigation({
        // selectedClass: 'Selected',
        // slidingSubmenus: true,
        theme: 'light',
        title: 'Meni'
      });

      var drawer = menu.offcanvas({
        position: 'right'
      });

      //  Open the menu.
      document.querySelector('#nav-icon')
        .addEventListener('click', evnt => {
          evnt.preventDefault();
          drawer.open();
        });

      $(".mob-menu-close").click(function () {
        drawer.close();
      });
    }

    /*
     * Counter number
     */
    $('.counter').counterUp({
      delay: 10,
      time: 2000
    });

  });
})(jQuery);